import { PopupButton } from 'react-calendly';
import React from 'react';
import css from './CalendlyBooking.module.scss';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isPlatformBrowser from '../../../util/isPlatformBrowser.util';

export default function CalendlyBooking({ schedulePageUrl, isPanel = true }) {
  const containerClass = isPanel ? css.container : css.containerNonPanel;
  const text = (
    <>
      <FontAwesomeIcon className={css.phoneIcon} icon={'fa-solid fa-phone-volume'} />
      <FormattedMessage id="ListingPage.calendlyCtaLink" />
    </>
  );
  return (
    isPlatformBrowser() && (
      <div className={containerClass}>
        <p className={css.message}>
          <FormattedMessage id="ListingPage.calendlyCtaMessage" />
        </p>
        <PopupButton
          className={css.popupButton}
          url={schedulePageUrl}
          rootElement={document.getElementById('root')}
          text={text}
        />
      </div>
    )
  );
}
