const characters = 'abcdefghijklmnopqrstuvwxyz';
// const characters = 'abcdefgh';
const length = 1;

export const randomizationTagHelper = () => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  console.log(`Tag: ${result}`);
  return result;
};

export const randomizationTagValue = () => {
  let result = 1;
  const attr = [1, 2];
  const length = attr.length;
  for (let i = 0; i < length; i++) {
    result = attr[Math.floor(Math.random() * length)];
  }
  console.log(`Value: ${result}`);
  return result;
};

export const randomizationTagShellGenerator = () => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < charactersLength; i++) {
    result =
      result +
      `flex-cli search set --key tag_${characters.charAt(
        i
      )} --type long --scope public -m doc-dev [slash]\n`;
  }
  return result;
};

export const randomizationSortAttribute = () => {
  let result = '';
  const attr = ['createdAt', 'price'];
  const length = attr.length;
  for (let i = 0; i < length; i++) {
    result = attr[Math.floor(Math.random() * length)];
  }
  return randomizationAscDesc() + result;
};

export const randomizationAscDesc = () => {
  let result = '';
  const attr = ['-', ''];
  const length = attr.length;
  for (let i = 0; i < length; i++) {
    result = attr[Math.floor(Math.random() * length)];
  }
  return result;
};

export const shuffleListings = listings => {
  let currentIndex = listings.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [listings[currentIndex], listings[randomIndex]] = [
      listings[randomIndex],
      listings[currentIndex],
    ];
  }

  return listings;
};
